import { ref, watch } from "@vue/composition-api";
import store from "@/store";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useGcpHelperList() {
  // Use toast
  const toast = useToast();

  const refGcpHelperListTable = ref(null);

  const tableColumns = ref([]);
  const gcpHelperRegion = ref("overview");
  const itemGcp = ref([]);

  const refetchData = () => {
    refGcpHelperListTable.value.refresh();
  };

  watch([gcpHelperRegion], () => {
    refetchData();
  });

  const selectRegion = (title) => {
    gcpHelperRegion.value = title;
    fetchGcpHelperByRegion();
  };
  const fetchGcpHelperByRegion = (ctx) => {
    const gcpHelperData = {
      gcpHelperRegion: gcpHelperRegion.value,
    };
    store.commit("appConfig/SPINNER_CONFIG", true);

    store
      .dispatch("app-gcp-helper/fetchGcpHelper", gcpHelperData)
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        tableColumns.value = [];

        for (const [key, value] of Object.entries(response.data.items[1])) {
          if (key == "region" || key == "symbols") {
            tableColumns.value.push();
          } else {
            if (key == "Products") {
              tableColumns.value.unshift({ key: key, sortable: false });
            } else {
              tableColumns.value.push({ key: key, sortable: false });
            }
          }
        }
        itemGcp.value = response.data.items;
        fetchGcpHelper = response.data.items;
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  let fetchGcpHelper = (ctx, callback) => {
    const gcpHelperData = {
      gcpHelperRegion: gcpHelperRegion.value,
    };
    store.commit("appConfig/SPINNER_CONFIG", true);

    store
      .dispatch("app-gcp-helper/fetchGcpHelper", gcpHelperData)
      .then((response) => {
        tableColumns.value = [];
        store.commit("appConfig/SPINNER_CONFIG", false);

        for (const [key, value] of Object.entries(response.data.items[1])) {
          if (key == "region" || key == "symbols") {
            tableColumns.value.push();
          } else {
            if (key == "Products") {
              tableColumns.value.unshift({ key: key, sortable: false });
            } else {
              tableColumns.value.push({ key: key, sortable: false });
            }
          }
        }
        itemGcp.value = response.data.items;
      })
      .catch((err) => {
        console.log(err);
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  return {
    fetchGcpHelper,
    refetchData,
    tableColumns,
    itemGcp,
    selectRegion,
    gcpHelperRegion,
    refGcpHelperListTable,
  };
}
