<template>
  <div>
    <b-card>
      <b-row class="d-flex justify-content-between">
        <b-col class="col-auto">
          <b-tabs>
            <b-tab
              title="Overview"
              active
              @click="selectRegion('overview')"
            ></b-tab>
            <b-tab
              title="Americas (regions)"
              @click="selectRegion('americas')"
            ></b-tab>
            <b-tab
              title="Europe (regions)"
              @click="selectRegion('europe')"
            ></b-tab>
            <b-tab
              title="Asia Pacific (regions)"
              @click="selectRegion('asia_pacific')"
            ></b-tab>
            <b-tab
              title="Multi-regions"
              @click="selectRegion('multi_regions')"
            ></b-tab>
          </b-tabs>
        </b-col>
        <b-col class="d-flex justify-content-end" cols="12" lg="3">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
            @input="resultQuery"
          />
        </b-col>
      </b-row>
      <hr />

      <b-row class="mt-1 mb-1">
        <b-col class="col-auto">
          <feather-icon
            icon="CheckCircleIcon"
            size="16"
            class="feather-success feather"
          />
          <span class="legend"> Available </span>
        </b-col>
        <b-col class="col-auto">
          <feather-icon icon="InfoIcon" size="16" />
          <span class="legend"> Service information</span>
        </b-col>
        <b-col class="col-auto">
          <feather-icon
            icon="AlertTriangleIcon"
            size="16"
            class="feather-warning feather"
          /><span class="legend"> One or more regions affected </span>
        </b-col>
      </b-row>

      <b-table
        v-if="itemGcp"
        v-show="!isSearchLoading"
        ref="refGcpHelperListTable"
        class="position-relative"
        :items="resultQuery.slice(50 * (currentPage - 1), 50 * currentPage)"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell()="data">
          <div
            class="text-nowrap text-center"
            v-if="data.value === 'available'"
          >
            <span class="align-text-top text-capitalize"
              ><feather-icon
                icon="CheckCircleIcon"
                size="16"
                class="feather-success feather"
            /></span>
          </div>

          <div
            class="text-nowrap text-center"
            v-if="data.value === 'service information'"
          >
            <span class="align-text-top text-capitalize"
              ><feather-icon icon="InfoIcon" size="16"
            /></span>
          </div>

          <div
            v-if="
              data.value !== 'available' && data.value !== 'service information'
            "
          >
            <span>{{ data.value }}</span>
          </div>
        </template>

        <template #head()="data">
          <span v-if="data.label !== 'Products'">
            <div class="text-center">
              {{ data.label }}
            </div>
          </span>

          <div v-else>
            {{ data.label }}
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-start"
          >
            <span class="text-muted"
              >Showing
              {{ perPage * (currentPage - 1) + (resultQuery.length ? 1 : 0) }}
              to {{ perPage * (currentPage - 1) + resultQuery.length }} of
              {{ resultQuery.length }} entries</span
            >
          </b-col>
          <!-- Pagination -->

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="resultQuery.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormInput,
  BSpinner,
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BPagination,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useGcpHelpersList from "./useGcpHelpersList";
import gcpHelperStoreModule from "../gcpHelperStoreModule";

export default {
  components: {
    BTable,
    BFormInput,
    BSpinner,
    BTab,
    BTabs,
    BRow,
    BCol,
    BCard,
    BPagination,
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        this.searchQueryData = this.itemGcp.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.Products.toLowerCase().includes(v));
        });
        return this.searchQueryData;
      } else {
        return this.itemGcp;
      }
    },
  },
  setup(props, { emit }) {
    const GCP_HELPER_APP_STORE_MODULE_NAME = "app-gcp-helper";
    let perPage = ref(50);
    let currentPage = ref(1);
    const searchQuery = ref("");
    const searchQueryData = ref([]);

    const {
      fetchGcpHelper,
      selectRegion,
      refetchData,
      tableColumns,
      itemGcp,
      refGcpHelperListTable,
      isSearchLoading,
    } = useGcpHelpersList();

    // Register module
    if (!store.hasModule(GCP_HELPER_APP_STORE_MODULE_NAME))
      store.registerModule(
        GCP_HELPER_APP_STORE_MODULE_NAME,
        gcpHelperStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GCP_HELPER_APP_STORE_MODULE_NAME))
        store.unregisterModule(GCP_HELPER_APP_STORE_MODULE_NAME);
    });

    fetchGcpHelper();

    return {
      fetchGcpHelper,
      selectRegion,
      refetchData,
      searchQueryData,
      tableColumns,
      perPage,
      currentPage,
      itemGcp,
      searchQuery,
      refGcpHelperListTable,
      isSearchLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.feather {
  margin-bottom: 0.2rem;
}
.legend {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
