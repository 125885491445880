import axios from "@axios";

export default {
  namespaced: true,
  state: {
    gcpHelper: [],
  },
  getters: {
    gcpHelpersList(state) {
      return state.gcpHelper;
    },
  },
  mutations: {
    SET_GCP_HELPERS(state, val) {
      state.gcpHelper = val;
    },
  },
  actions: {
    fetchGcpHelper(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/gcpstatus/${data.gcpHelperRegion}`, { params: { page: data.currentPage, per_page: data.perPage, } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
}